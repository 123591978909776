import { Spinner } from 'components/Elements/Spinner';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as React from 'react';

const variants = {
  primary: 'bg-[#4F5468] text-white hover:bg-gray-500:text-[#142852]',
  secondary: 'bg-orange-500  text-white hover:bg-gray-50:text-secondary-100',
  inverse: 'bg-white text-[#142852]  hover:bg-primary-100:text-white ',
  danger: 'bg-red-600 text-white hover:bg-red-50:text-red-600',
  dark: 'bg-gray-800 text-white hover:bg-gray-50:text-gray-700',
  outline: 'border border-primary-100 text-primary-100 hover:bg-primary-100:text-white ',
};

const sizes = {
  sm: 'py-3 px-4 font-black text-sm',
  md: 'py-3 px-6 font-black text-md',
  lg: 'py-3 px-8 font-black text-lg',
};

export const Button = React.forwardRef(
  (
    {
      type = 'button',
      className = '',
      variant = 'primary',
      size = 'md',
      isLoading = false,
      startIcon,
      endIcon,
      disabled,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        disabled={disabled}
        className={clsx(
          'flex justify-center items-center rounded-full disabled:opacity-70 disabled:cursor-not-allowed shadow-sm font-medium focus:outline-none',
          variants[variant],
          sizes[size],
          className
        )}
        {...props}
      >
        {isLoading && <Spinner size="sm" className="text-current" />}
        {!isLoading && startIcon}
        <span className="mx-2">{props.children}</span> {!isLoading && endIcon}
      </button>
    );
  }
);

Button.displayName = 'Button';

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'inverse', 'danger']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  isLoading: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};
