// MainLayout.jsx
import { Head } from 'components/Head';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import LogoDefault from 'assets/logo-default.svg';
import { Logo } from 'components/Layout/components/Logo';
import AppleStore from 'assets/apple_store.svg';
import PlayStore from 'assets/play_store.svg';

export const MainLayout = ({ title, description, children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      {/* Meta Information */}
      <Head title={title} description={description} />

      {/* Header */}
      <header className="londrina-solid-regular">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="h-16 flex md:block justify-between md:my-8 md:mx-12">
            <div className="flex flex-row items-center justify-between">
              <Link className="flex-shrink-0" to="/">
                <img className="h-12 w-auto" src={LogoDefault} alt="JustKnow Logo" />
              </Link>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                <Link
                  to="#about"
                  className="text-gray-900 text-lg px-1 pt-1 border-b-2 border-transparent hover:border-indigo-500"
                >
                  About
                </Link>
                <Link
                  to="#blog"
                  className="text-gray-900 text-lg px-1 pt-1 border-b-2 border-transparent hover:border-indigo-500"
                >
                  Our Blog
                </Link>
                <Link
                  to="#join"
                  className="text-gray-900 text-lg px-1 pt-1 border-b-2 border-transparent hover:border-indigo-500"
                >
                  Join Us
                </Link>
                <Link
                  to="#contact"
                  className="text-white bg-[#FF6937] rounded-full px-4 py-2 hover:border-indigo-500"
                >
                  Contact Us
                </Link>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="bg-white p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100"
                >
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path
                      className={!isMenuOpen ? 'block' : 'hidden'}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                    <path
                      className={isMenuOpen ? 'block' : 'hidden'}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              <Link
                to="#about"
                className="block pl-3 pr-4 py-2 border-l-4 border-indigo-500 text-base font-medium text-indigo-700 bg-indigo-50"
              >
                About
              </Link>
              <Link
                to="#blog"
                className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:bg-gray-50 hover:border-gray-300"
              >
                Our Blog
              </Link>
              <Link
                to="#join"
                className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:bg-gray-50 hover:border-gray-300"
              >
                Join Us
              </Link>
              <Link
                to="#contact"
                className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:bg-gray-50 hover:border-gray-300"
              >
                Contact Us
              </Link>
            </div>
          </div>
        )}
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-12">{children}</main>

      {/* Footer */}
      <footer className="mx-8 md:mx-32 mb-12 londrina-solid-thin">
        <div className="flex flex-row items-center justify-center mb-8">
          <Logo />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h4 className="text-lg londrina-solid-black mb-4">Get In Touch</h4>
            <div className="space-y-3">
              <p>Plot 593 off Muganzi Awongererwa Road, Makerere, Kampala</p>
              <p>+256 782692271</p>
              <p>info@justknow.ug</p>
              <Link to="/our-blog" className="underline hover:text-[#FF6937]">
                Our Blog
              </Link>
            </div>
          </div>
          <div>
            <h4 className="text-lg londrina-solid-black mb-4">Join JustKnow</h4>
            <div className="space-y-3">
              <Link to="/careers" className="underline hover:text-[#FF6937]">
                Careers
              </Link>
              <br />
              <Link to="/join-as-a-rider" className="underline hover:text-[#FF6937]">
                Join as a rider
              </Link>
              <br />
              <Link to="/register-your-business" className="underline hover:text-[#FF6937]">
                Register your business
              </Link>
            </div>
          </div>
          <div className="">
            <div className="text-lg londrina-solid-black mb-4">Support</div>
            <div className="flex flex-col space-y-3">
              <Link to="/terms-of-service" className="underline hover:text-[#FF6937]">
                Terms of Service
              </Link>
              <Link to="/privacy" className="underline hover:text-[#FF6937]">
                Privacy Policy
              </Link>
              <Link to="/account-deletion-policy" className="underline hover:text-[#FF6937]">
                Account Deletion Policy
              </Link>
            </div>
          </div>

          <div className="">
            <div className="text-lg londrina-solid-black mb-4">Download App</div>
            <div className="flex flex-col space-y-3">
              <a href="" target="_blank" to="/our-blog" className="underline hover:text-[#FF6937]">
                <img src={AppleStore} alt="Apple Store" />
              </a>
              <a href="" target="_blank" to="/our-blog" className="underline hover:text-[#FF6937]">
                <img src={PlayStore} alt="Play Store" />
              </a>
            </div>
          </div>
        </div>
        <div className="mb-12">
          <div className="londrina-solid-thin text-md text-center">
            © JustKnow 2024, All rights reserved.
          </div>
        </div>
      </footer>
    </>
  );
};
