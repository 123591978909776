import React from 'react';

export const NameAvatar = ({ initials }) => {
  const getInitials = (name) => {
    const initialsArray = name.split(' ');
    return initialsArray
      .map((word) => word.charAt(0))
      .join('')
      .toUpperCase();
  };

  return (
    <div
      style={{
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        backgroundColor: '#E0991C',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
      }}
    >
      {getInitials(initials)}
    </div>
  );
};
